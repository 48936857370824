import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Row, Col, Video } from '../components/global'

const Videos = () => (
  <Layout>
    <SEO title="Videos" />
    <div className="container container-last">
      <h1>Videos</h1>
      <Row>
        <Col>
          <Video src="https://www.youtube.com/embed/aKbDcr-FKIE" />
        </Col>
      </Row>
      <hr />
      <h2>
        Electrical <em>Shortcuts</em>
      </h2>
      <Row>
        <Col width="49%">
          <Video
            title="TW #10 - Electrical Testing Shortcuts with Vince Fischelli: Part One (Battery Testing)"
            src="https://www.youtube.com/embed/7UAVc4Z2lhY"
          />
          <p>
            TW #10 - Electrical Testing Shortcuts with Vince Fischelli: Part One (Battery Testing)
          </p>
        </Col>
        <Col width="49%">
          <Video
            title="ITW #11 - Electrical Testing Shortcuts with Vince Fischelli: Part Two (Starting Systems)"
            src="https://www.youtube.com/embed/EDciaypL8Vw"
          />
          <p>
            ITW #11 - Electrical Testing Shortcuts with Vince Fischelli: Part Two (Starting Systems)
          </p>
        </Col>
      </Row>
      <Row>
        <Col width="49%">
          <Video
            title="ITW #12 - Electrical Testing Shortcuts with Vince Fischelli: Part 3 (Charging Systems)"
            src="https://www.youtube.com/embed/4EFzX3SN6ck"
          />
          <p>
            ITW #12 - Electrical Testing Shortcuts with Vince Fischelli: Part 3 (Charging Systems)
          </p>
        </Col>
      </Row>
      <hr />
      <h2>Hands-On Training for Auto – Truck – H.D. Techs</h2>
      <Row>
        <Col width="49%">
          <Video
            title="H 111A Automotive Electrical Troubleshooting Training with Vince Fischelli"
            src="https://www.youtube.com/embed/wIWic4sGgvA"
          />
          <p>H 111A Automotive Electrical Troubleshooting Training with Vince Fischelli</p>
        </Col>
        <Col width="49%">
          <Video
            title="H-113 Troubleshooting Automotive DC Motor Circuits with Vince Fischelli"
            src="https://www.youtube.com/embed/1SSfQ7qHnC0"
          />
          <p>H-113 Troubleshooting Automotive DC Motor Circuits with Vince Fischelli</p>
        </Col>
      </Row>
      <Row>
        <Col width="49%">
          <Video
            title="H-115 Troubleshooting Automotive Relay Circuits with Vince Fischelli"
            src="https://www.youtube.com/embed/1WhsU0SW-k0"
          />
          <p>H-115 Troubleshooting Automotive Relay Circuits with Vince Fischelli</p>
        </Col>
        <Col width="49%">
          <Video
            title="H 116 Automotive Wire Harness Troubleshooting Training with Vince Fischelli"
            src="https://www.youtube.com/embed/rxwyTa2cjQg"
          />
          <p>H 116 Automotive Wire Harness Troubleshooting Training with Vince Fischelli</p>
        </Col>
      </Row>
      <Row>
        <Col width="49%">
          <Video
            title="H-200 Automotive CAN Bus Troubleshooting with Vince Fischelli"
            src="https://www.youtube.com/embed/_Mb2mYUJG3s"
          />
          <p>H-200 Automotive CAN Bus Troubleshooting with Vince Fischelli</p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Video
            title="Veejer Enterprises @ VISION HiTech Training &amp; Expo"
            src="https://www.youtube.com/embed/s4CZ_ggScjQ"
          />
          <p>Veejer Enterprises @ VISION HiTech Training &amp; Expo</p>
        </Col>
      </Row>
    </div>
  </Layout>
)

export default Videos
